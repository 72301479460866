import React from "react"
import { Container, Row, Col, Image } from "react-bootstrap"
import caitlinHeadshot from "../assets/images/caitlin-headshot.png"
import robHeadshot from "../assets/images/rob-headshot.png"
import fgRectangle from "../assets/images/fg-rectangle.png"
import caitlin from "../assets/images/headshot-caitlin2.png"
import rob from "../assets/images/headshot-rob2.png"
import karla from "../assets/images/headshot-karla.png"
import tawney from "../assets/images/headshot-tawney.png"
import tj from "../assets/images/headshot-tj.png"
import peyton from "../assets/images/headshot-peyton.png"
import joe from "../assets/images/headshot-joe-corr.png"
import decklan from "../assets/images/headshot-decklan-mcgee.png"
import emma from "../assets/images/headshot-emma-feldt.png"

import "../assets/css/team.css"

const Team = () => {
  return (
    <section className="team">
      <Container fluid className="team-container">
        <Row className="justify-content-center team-row">
          <Col>
            <Image src={caitlin} fluid />
            <div className="text-center">
              <p className="black-special">Caitlin Wilson</p>
              <p>CEO</p>
              <p>CO-FOUNDER</p>
            </div>
          </Col>
          <Col>
            <Image src={tj} fluid />
            <div className="text-center">
              <p className="black-special">TJ VerValin</p>
              <p>VP OPERATIONS</p>
            </div>
          </Col>
          <Col>
            <Image src={peyton} fluid />
            <div className="text-center">
              <p className="black-special">Peyton Bell</p>
              <p>VP STRATEGY & PARTNERSHIPS</p>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center team-row more-side-padding">
          <Col>
            <Image src={rob} fluid />
            <div className="text-center">
              <p>Rob Allen</p>
              <p>VP BUSINESS DEVELOPMENT</p>
              <p>CO-FOUNDER</p>
            </div>
          </Col>
          <Col>
            <Image src={tawney} fluid />
            <div className="text-center">
              <p>Tawney Goularte</p>
              <p>DIRECTOR OF NATIONAL ACCOUNTS</p>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center team-row">
       
          <Col>
            <Image src={karla} fluid />
            <div className="text-center">
              <p>Karla Ibanez Rutiaga</p>
              <p>FULFILLMENT TEAM LEADER</p>
            </div>
          </Col>
          <Col>
            <Image src={emma} fluid />
            <div className="text-center">
              <p>Emma Von Feldt</p>
              <p>CLIENT SUCCESS LEAD</p>
            </div>
          </Col>
        </Row>
        <Row className="center">
          <div className="block-title join-team">
            <h2>Interested in joining our team?</h2>
            <a href="#jobs">
              <button class="jobs-button">View Open Positions</button>
            </a>
          </div>
        </Row>
        <img src={fgRectangle} />
        <br />
      </Container>
    </section>
  )
}

export default Team

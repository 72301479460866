import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import ServiceCard from "./cards/service-card"
import BlockTitle from "./block-title"
import blockTitleCircle from "../assets/images/round-circle-1-1.png"
import squareLogo from "../assets/images/square-logo.png"
import squareLogoLg from "../assets/images/square-logo-lg.png"
import { JobAccordionCard } from "./cards/accordion-card"

import "../assets/css/jobs.css"

const ACCORDION_DATA = [
  {
    status: true,
    title: "Warehouse Associate",
    sections: {
      "ROLE AND RESPONSIBILITIES": `We are looking for a Warehouse Associate to support our rapidly growing, fast-paced Operations team. Additional
      duties and responsibilities commensurate and as assigned to by the Director of Operations and by FG Fulfillment
      ownership.`,
      "QUALIFICATIONS AND EDUCATION REQUIREMENTS": `High School GED required
      Previous proven work experience within the warehouse and/or fulfillment space preferred
      Ability to stand for long periods of time and lift heavy boxes
      Strong attention to detail, demonstrable systems understanding with good communication skills
      Knowledge of USDA Regulatory Standards relative to food product warehousing (FIFO, HACCP, etc)`,
      "PREFERRED SKILLS & GENERAL JOB DESCRIPTION": `Efficient & quick learner. Proficient with computer work and easy-to-learn shipping software and inventory
      management systems. Willing to work hard and stay focused to maintain a high cadence of perfectly packed boxes
      for our direct-to-consumer program. Ability to follow directions closely and provide every client with a unique
      unboxing experience.
      - Fulfill orders with a strong attention to detail by following the pack-out guides
      - Keep work area(s) clean and organized
      - Restock packing stations with products, boxes, tape, etc
      - Perform regular inventory protocols for a number of SKUs
      - Take ownership and pride in your daily work by working alongside other Team Members to complete all
      assigned tasks
      - Help foster a Team based environment by demonstrating a positive, can-do attitude, exemplary work ethic
      and problem solving curiosity
      - Adhere to State & Federal health and safety protocols; attending trainings as necessary`,
    },
    link: "https://yn02pjfqi1y.typeform.com/to/nq1Y8GOt",
  },
]

const Jobs = () => {
  return (
    <section className="jobs faq-style-one" id="jobs">
      <Container>
        <BlockTitle
          textAlign="center"
          image={squareLogoLg}
          title={`Open Positions`}
          className="job-title"
        />
        <Row className="center">
          <div className="specialize caps center job-description">
            JOIN THE FULLGOOD TEAM
          </div>
        </Row>
        <Row className="job-row">
          <div className="accrodion-grp job-faq-accrodion">
            {ACCORDION_DATA.map(({ status, title, sections, link }, index) => (
              <JobAccordionCard
                className="job-card"
                key={`accordion-card-${index}`}
                status={status}
                title={title}
                sections={sections}
                link={link}
              />
            ))}
          </div>
        </Row>
      </Container>
    </section>
  )
}

export default Jobs

import React, { useState } from "react"
import { Container, Row, Col } from "react-bootstrap"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Autoplay, Thumbs } from "swiper"

import BlockTitleImgless from "./block-title-imgless"

import swayLogo from "../assets/images/sway-logo.png"
import fgRectangle from "../assets/images/fg-rectangle.png"
import goodLoveLogo from "../assets/images/good-love-logo.png"
import breckLogo from "../assets/images/breck-logo.png"
import nuzestLogo from "../assets/images/nuzest-logo.png"
import tbLogo from "../assets/images/TB-LOGO.png"
import rcLogo from "../assets/images/RC-LOGO.png"
import logoFlouwerco from "../assets/images/logo-flouwerco.png"
import cfwLogo from "../assets/images/CFW-LOGO.png"
import ccLogo from "../assets/images/CC-LOGO.png"
import natBoulderLogo from "../assets/images/naturally-boulder-logo.png"

SwiperCore.use([Autoplay, Thumbs])

const TESTIMONIALS_DATA = [
  {
    title: "-Emily",
    content:
      "Full Good was such a lifesaver for me going into Q4 transitioning away from an unreliable 3PL partner. I wasn't sure if we were going to be able to make the move in time, during the busiest season of the year for eCommerce companies, but they made it happen, and even provided transportation and logistics for getting all of my inventory packed up and out of the other place. It’s been so nice to work with a company that can be really personal and flexible with our needs, so we can offer unique options for customers instead of having to follow a strict guideline of what’s possible. \n \n Thank you Full Good team!!",
    image: swayLogo,
    // gets Character count for each slide to adjust slider time
    get charCount() {
      console.log(this.content.length * 16.5)
      return this.content.length
    }
  },

  {
    title: "-Chennelle",
    content:
      "Full Good has been integral in growing our start-up! Because of their attention to detail, customer service, and awesome team, we’ve been able to level up by increasing sales and production. Best partnership ever! Thank you Full Good team!!",
    image: goodLoveLogo,
    get charCount() {
      return this.content.length
    }
  },
  {
    title: "-Nick",
    content:
      "Having a reliable fulfillment partner has allowed us to focus on other important aspects of our business, which is going to allow our Denver based fresh-frozen dog food company to scale with confidence.    We look forward to many more happy doggie customers thanks to the help of Full Good! Woof! Woof!",
    image: breckLogo,
    get charCount() {
      return this.content.length
    }
  },
  {
    title: "-GR, Red Clay",
    content:
      "Full Good has been an absolute game changer for us.  While they execute highly on their bread and butter options of fulfillment and inventory management, the depth of their value to us is rooted in their service and support of our overall business.  We are incredibly thankful for them as a partner--they eliminate stressors and make our business better!",
    image: rcLogo,
    get charCount() {
      return this.content.length
    }
  },

  {
    title: "-Jonathan, Nuzest",
    content:
      "Since partnering with Full Good in 2023, we have experienced a significant upgrade in our logistics and customer service. Full Good's responsive and problem-solving approach has made our supply chain management both more cost-effective and reliable. Their team's flexibility and quick action are commendable, making them an integral part of our operations. Overall working with Full Good has been an enjoyable, hassle-free, productive partnership that is contributing to our growth and customer satisfaction.",
    image: nuzestLogo,
    get charCount() {
      return this.content.length
    }
  },
  {
    title: "-Kristen, Flouwer Co.",
    content:
      "We met FullGood Fulfillment at a pivotal point in our business and although we may not have realized it then, we certainly would not be where we are today or on the path for where we're headed without their partnership. We value our relationships with every single person on the FG Team as well as their work ethic. There is never hesitancy to consider new challenges or help mentoring us through new opportunities. Their willingness to roll-up their sleeves to ensure the job is done well and their deep network of other vendors for outside auxiliary services is an added BONUS!",
    image: logoFlouwerco,
    get charCount() {
      return this.content.length
    }
  },
  {
    title: "-Jeff, Trail Butter",
    content:
      "Full Good has been a wonderful partner for our business! The quality of work, high level of communication, and responsiveness along with a general trust that they have our back and want us to be successful has made it a joy to work with the whole team.",
    image: tbLogo,
    get charCount() {
      return this.content.length
    }
  },
]

const Testimonials = () => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null)
  const testimonialsCarouselOption = {
    spaceBetween: 0,
    slidesPerView: 1,
    autoplay: {
      delay: 10000,
    },
  }
  // TODO: this is not used
  const testimonialsThumOption = {
    spaceBetween: 0,
    slidesPerView: 1,
    speed: 10000,
    autoplay: {
      delay: 10000,
    },
  }
  return (
    <section className="center testimonials" id="testimonials">
      <img src={fgRectangle} />
      <Container>
        <Col>
          <Row className="center">
            <BlockTitleImgless
              textAlign="center"
              title="What our clients are saying"
            ></BlockTitleImgless>
          </Row>
          <Row className="center">
            <Swiper
              {...testimonialsCarouselOption}
              thumbs={{ swiper: thumbsSwiper }}
              className="slider testimonials-slider testimonial-section"
            >
              {TESTIMONIALS_DATA.map(({ title, content, image, charCount }, index) => (
                <SwiperSlide
                  key={`testimonials-card-${index}`}
                  // adjusts timing based on character count
                  data-swiper-autoplay={charCount * 16}
                  className="slide-item"
                >
                  <div className="center">
                    <img src={image} />
                  </div>
                  <br />
                  <div className="single-testi-one">
                    <p>{content}</p>
                    <h5>{title}</h5>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </Row>
        </Col>
      </Container>
      <img src={fgRectangle} />
    </section>
  )
}

export default Testimonials

import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import BlockTitle from "./block-title"
import blockTitleCircle from "../assets/images/round-circle-1-2.png"
import squareLogo from "../assets/images/square-logo.png"
// import mocup from "../assets/images/moc-1-1.png"
import warehouse from "../assets/images/warehouse-iso.png"
import { SliderButton } from "@typeform/embed-react"

const FeatureTwo = () => {
  return (
    <section className="feature-style-two" id="about-us">
      <Container>
        <Row>
          <Col lg={6} className="vertical-center">
            <div className="image-block">
              <img src={warehouse} alt="Awesome alter Text" />
            </div>
          </Col>
          <Col lg={6}>
            <div className="content-block">
              <BlockTitle
                textAlign="left"
                image={squareLogo}
                title={`Client obsessed fulfillment, inventory management, supply chain and logistics experts.`}
              />
              <p>
                Full Good provides CPG brands with hassle-free product support solutions.
              </p>
              <ul className="feature-lists">
                <li>
                  <i className="fa fa-check"></i> Clear Communication
                </li>
                <li>
                  <i className="fa fa-check"></i> True Transparency
                </li>
                <li>
                  <i className="fa fa-check"></i> Collaborative business
                  practices
                </li>
                <li>
                  <i className="fa fa-check"></i> Perfected direct-to-consumer
                  deliveries
                </li>
              </ul>
              {/* <a href="#none" className="feature-btn">
                Get Started
              </a> */}
              <SliderButton href="#none" className="feature-btn" id="TC4d5mZv">
                <span>Get Started</span>
              </SliderButton>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default FeatureTwo

import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import "../assets/css/hero.css"

const TitleParagraph1 = () => {
  return (
    <Container>
      <br></br>
      <Row className="left">
        <div className="pre-header">Why Full Good? </div>
      </Row>
      <Row className="left">
        <div>
          Our team is obsessed with solving our clients’ toughest logistics
          problems by providing packing consultations, simple product management
          solutions, transparent order fulfillment, and low cost shipping so you
          can focus on sustainable growth strategies.
        </div>{" "}
      </Row>
      <br />
      <Row className="left">
        <div>
          Full Good exceeds the standards required for storing, packing, and
          shipping food products and packaged goods. For brands that produce
          perishable goods, it is essential to partner with a fulfillment
          company that understands the unique shipping and delivery requirements
          for these products. Our team of expert CPG and food professionals has
          created a hassle-free logistics solution for new and emerging brands
          to scale quickly and sustainably.
        </div>
      </Row>
      <br />
      <br />
    </Container>
  )
}

export default TitleParagraph1

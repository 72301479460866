import React from "react"
import Layout from "../components/layout"
import Header from "../components/header-one"
import Hero from "../components/hero"
import Subhero from "../components/subhero-one"
import FunFact from "../components/funfact"
import SpecializingIn from "../components/specializing-in"
import FeatureOne from "../components/feature-one"
import WarehouseSection from "../components/warehouse-section"
import Pricing from "../components/pricing"
import Testimonials from "../components/testimonials"
import TestimonialsOG from "../components/testimonials-og"
import Brands from "../components/brands"
import Faq from "../components/faq"
import BlogHome from "../components/blog-home"
import Subscribe from "../components/subscribe"
import Footer from "../components/footer"
import Seo from "../components/seo"
import Map from "../components/map"
import Team from "../components/team"
import TitleParagraph1 from "../components/title-paragraph"
import FullGoodMission from "../components/fullgoodmission"
import Jobs from "../components/jobs"
import Affiliates from "../components/afiiliates"

const Home = () => {
  return (
    <Layout pageTitle="FULL GOOD">
      <Seo title="FULL GOOD" />
      <Header />
      <Hero />
      <Subhero />
      <WarehouseSection />
      <TitleParagraph1 />
      <Map />
      <FullGoodMission />
      <Team />
      <SpecializingIn />
      <Testimonials />
      <Faq />
      <Jobs />
      <Affiliates />
      {/* <FeatureOne /> */}
      <Footer />
    </Layout>
  )
}

export default Home

import React, { useState } from "react"
import union from "../../assets/images/correct-union.png"

// had to fix union name
const AccordionCard = props => {
  const [status, setStatus] = useState(props.status)
  const handleClick = () => {
    setStatus(!status)
  }
  return (
    <div
      className={`accrodion ${props.className} ${
        status === true ? "active" : ""
      }`}
    >
      <div className="accrodion-inner">
        <div
          className="accrodion-title"
          onClick={handleClick}
          onKeyDown={handleClick}
          role="button"
          tabIndex={0}
        >
          <h4>{props.title}</h4>
        </div>
        {status === true ? (
          <div className="accrodion-content">
            <div className="inner">
              <p>{props.content}</p>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export const JobAccordionCard = props => {
  const [status, setStatus] = useState(props.status)
  const handleClick = () => {
    setStatus(!status)
  }
  console.log("props.sections are:", props.sections)

  return (
    <div
      className={`accrodion ${props.className} ${
        status === true ? "active" : ""
      }`}
    >
      <div className="accrodion-inner">
        <div
          className="job-accrodion-title"
          onClick={handleClick}
          onKeyDown={handleClick}
          role="button"
          tabIndex={0}
        >
          <h4>{props.title}</h4>
          <img
            className={`union ${status === true ? "active" : ""}`}
            src={union}
          />
        </div>
        {/* add an image, of a union, that flips upside down when the card is active */}

        {status === true && props.sections ? (
          <div className="accrodion-content">
            <div className="center">
              <br></br>
              <a href={`${props.link}`}>
                <button className="jobs-button">Apply Now</button>
              </a>
            </div>
            <div className="inner">
              {Object.keys(props.sections).map((key, index) => (
                <div key={index}>
                  <h5>{key}</h5>
                  <p>{props.sections[key]}</p>
                  <br></br>
                </div>
              ))}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default AccordionCard

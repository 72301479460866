import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import singleBox from "../assets/images/fg-box-single.png"
import "../assets/css/hero.css"

const FullGoodMission = () => {
  return (
    <Container>
      <Row className="left">
        <div className="pre-header">Our Mission </div>
      </Row>
      <Row className="left">
        <div>
          Reflecting on years of brand and product development experience in 
          family-owned, multi-generational food and CPG businesses led us to 
          brainstorm how to help emerging brands scale. We believe in GOOD 
          products. We believe in GOOD business practices. We believe in 
          providing a full agency of operational services for CPG brands 
          to scale responsibly and strategically.
        </div>
      </Row>
      <br />
      <Row className="left">
        <div>
          Full Good is a team of product-obsessed problem solvers who 
          wholeheartedly believe in the missions of each of the brands 
          that we represent. While our expertise lies in hassle-free 
          logistics, we are true partners for growth and our offerings 
          extend well beyond fulfillment. We have assembled an expert team 
          of branding professionals, from website developers, social media 
          experts and corrugate designers to trade show consultants.
        </div>
      </Row>
      <br />
      <Row className="pl-0">
        <div className="box-wrapper pb-4">
          <div className="box-left">
          We are here to help scale your brand with customized services 
          and transparent pricing. We solve the toughest logistics 
          challenges and maximize operational efficiency. We roll our 
          sleeves up and work hard for our clients.
          </div>
        <div className="d-flex align-items-end justify-content-end bottom-image">
          <img src={singleBox} className="img-fluid" alt="Awesome alter Text" />
        </div>
        </div>
      </Row>
      <br />
      <br />
    </Container>
  )
}

export default FullGoodMission

import React, { useState } from "react"
import { Container, Row, Col } from "react-bootstrap"

import BlockTitleImgless from "./block-title-imgless"

import cfwLogo from "../assets/images/CFW-LOGO.png"
import ccLogo from "../assets/images/CC-LOGO.png"
import natBoulderLogo from "../assets/images/naturally-boulder-logo.png"

import "../assets/css/affiliates.css"


const Affiliates = () => {
  return (
    <section className="center affiliates" id="affiliates">
      <Container>
        <Col>
          <Row className="center">
            <BlockTitleImgless
              textAlign="affiliates"
              title="We Proudly Support"
            > </BlockTitleImgless>

          </Row>
          <Row className="center aff-callout">
          <p className="aff-callout">Full Good is an active member and affiliate of these great organizations:</p>
          </Row>
          <Row className="center">
            <div className="affiliates-container">
              <a href="https://www.coloradoproud.com/" target="_blank">
              <img className="aff-logo" src={ccLogo} />
              </a>
              <a href="https://www.coloradofoodworks.org/" target="_blank">
              <img className="aff-logo" src={cfwLogo} />
              </a>
              <a href="https://www.naturallyboulder.org/" target="_blank">
              <img className="aff-logo" src={natBoulderLogo} />
              </a>
            </div>
          </Row>
        </Col>
      </Container>
    </section>
  )
}

export default Affiliates

import React from "react"
import { Container } from "react-bootstrap"
import BlockTitle from "./block-title"
import AccordionCard from "./cards/accordion-card"
import blockTitleCircle from "../assets/images/round-circle-1-7.png"
import squareLogoLg from "../assets/images/square-logo-lg.png"

const ACCORDION_DATA = [
  {
    status: true,
    content:
      "We offer a wide range of services for e-commerce and CPG brands that range from basic storage to fully integrated programming that incorporates support every step of the way so our clients can focus on what they do best - selling their products!",
    title: "What services does Full Good Fulfillment & Logistics provide?",
  },
  {
    status: false,
    content:
      "Our mission is to position CPG brands for sustainable growth by supporting and optimizing logistics and fulfillments processes through transparency, attention to detail, adding true value and providing FULL GOOD support. Our clients are emerging CPG businesses that are scaling quickly nationally and world-wide. Outsourcing operational needs allows our clients to focus on growing their product ranges and supporting their customers. We don’t take our jobs lightly. Our clients put their trust in us because we know that what we do everyday affects brand integrity. We treat our clients’ brands as if they are our own and ensure that every package and pallet that leaves our warehouse meets our exceptionally high standards.",
    title: "Mission / Vision / Values",
  },
  {
    status: false,
    content: (
      <div>
        <div>Improve CPG business’ operations through:</div>
        <ul>
          <li>National and International Logistics solutions and management</li>
          <li>Product and inventory management</li>
          <li>POS / E-commerce integration for DTC and B2B</li>
          <li>Packout optimization </li>
          <li>Corrugate design and development</li>
          <li>Order fulfillment and management</li>
          <li>DTC customer service management</li>
          <li>Access to our network of industry professionals</li>
          <li>Sustainable growth strategy recommendations and consulting</li>
        </ul>
      </div>
    ),
    title: "What does Full Good do?",
  },
  {
    status: false,
    content: (
      <>
        <p>
        By partnering with us to manage your brand’s operational, fulfillment and 
        logistics needs, you can focus on what drives your business’ growth. We work 
        with you to identify the most economic and efficient solution through our 
        customizable and growth-focused approach for fulfillment and inventory management. 
        We tailor our services to your brand’s specific needs! 
        </p>
      </>
    ),
    title: "What are the benefits of outsourcing fulfillment and logistics?",
  },
  {
    status: false,
    content:
      "Our software systems align with your ecommerce websites and orders flow in automatically. We can also process pallet shipments and wholesale orders. We have a customer success team that is designated to every account for a seamless setup.",
    title: "How do my orders get processed at Full Good?",
  },
  {
    status: false,
    content:
      "Our goal is to help our customers save time and money. We have master accounts with all major carriers (FedEx,UPS, USPS, DHL). If there is a carrier that you would like to work with, we will be happy to explore the partnership.",
    title: "What shipping carriers does Full Good work with?",
  },
  {
    status: false,
    content:
      "We ship products out for our clients Monday - Friday. We do not offer weekend shipments. We will ship your products out same-day as long as orders are in our system by Noon MST.",
    title: "What days do you ship products?",
  },
  {
    status: false,
    content:
      "Yes! We provide LTL and FTL services to pick up our client’s CPG products anywhere nationally as well as internationally. ",
    title: "Can Full Good prepare a large truck pickup from my manufacturer?",
  },
  {
    status: false,
    content:
      "We prefer to learn about your brand and how we can best service the account for scalability and success. We then prepare a customized scope of work and create a unique storage, fulfillment and logistics program.",
    title:
      "What pricing is offered? How do I get my business set up with services?",
  },
  {
    status: false,
    content:
      "Yes! Of course! We just ask for a heads up so we know when to expect you. We also have a conference room that is available for our client’s use.",
    title: "Are visits allowed to the Full Good warehouse?",
  },
  {
    status: false,
    content:
      "No, unfortunately we do not provide insurance for our client’s products. Per our contract, we ask that all our clients carry insurance and add our location as a secondary location.",
    title:
      "Does Full Good offer insurance for products stored at the warehouse in Denver?",
  },
]

const Faq = () => {
  return (
    <section className="faq-style-one" id="faq">
      <Container>
        <BlockTitle
          textAlign="center"
          image={squareLogoLg}
          title={`Questions?`}
        />
        <div className="accrodion-grp faq-accrodion">
          {ACCORDION_DATA.map(({ status, title, content }, index) => (
            <AccordionCard
              key={`accordion-card-${index}`}
              status={status}
              title={title}
              content={content}
            />
          ))}
        </div>
      </Container>
    </section>
  )
}

export default Faq

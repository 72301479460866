import React, { useState, useEffect } from "react"
import { Container, Row, Col } from "react-bootstrap"
import Typewriter from "typewriter-effect"
import subheroLogo from "../assets/images/subhero-logo.png"
import fgRectangle from "../assets/images/fg-rectangle.png"

const SubheroOne = () => {
  return (
    <Container>
      <Row className="center">
        <img
          src={fgRectangle}
          className="subhero-image"
          alt="Awesome alter Text"
        />
      </Row>
      <br />
      <br />
      <Row className="center">
        <img
          src={subheroLogo}
          className="subhero-image"
          alt="Awesome alter Text"
        />
      </Row>
      <Row className="center">
        <div className="caps headline-thin">(English) Adj. </div>
        <div className="caps headline">
          something that is beyond good, better than good.
        </div>
      </Row>
      <Row className="center">
        <div className="headline-thin purple">
          Your partner for{" "}
          <span className="slogan-container">
            <b>
              <Typewriter
                onInit={typewriter => {
                  typewriter
                    .typeString("SUSTAINABLE")
                    .pauseFor(4000)
                    .deleteAll()
                    .typeString("SCALABLE")
                    .pauseFor(4000)
                    .deleteAll()
                    .typeString("STRATEGIC")
                    .pauseFor(4000)
                    .deleteAll()
                    .typeString("STEADY")
                    .pauseFor(4000)
                    .deleteAll()
                    .typeString("SUSTAINABLE")
                    .pauseFor(4000)
                    .deleteAll()
                    .typeString("SCALABLE")
                    .pauseFor(4000)
                    .deleteAll()
                    .typeString("STRATEGIC")
                    .pauseFor(4000)
                    .deleteAll()
                    .typeString("STEADY")
                    .pauseFor(4000)
                    .deleteAll()
                    .typeString("SUSTAINABLE")
                    .pauseFor(4000)
                    .deleteAll()
                    .typeString("SCALABLE")
                    .pauseFor(4000)
                    .deleteAll()
                    .typeString("STRATEGIC")
                    .pauseFor(4000)
                    .deleteAll()
                    .typeString("STEADY")
                    .pauseFor(4000)
                    .deleteAll()
                    .typeString("SUSTAINABLE")
                    .pauseFor(4000)
                    .deleteAll()
                    .typeString("SCALABLE")
                    .pauseFor(4000)
                    .deleteAll()
                    .typeString("STRATEGIC")
                    .pauseFor(4000)
                    .deleteAll()
                    .typeString("STEADY")
                    .pauseFor(4000)
                    .deleteAll()
                    .typeString("SUSTAINABLE")
                    .pauseFor(4000)
                    .deleteAll()
                    .typeString("SCALABLE")
                    .pauseFor(4000)
                    .deleteAll()
                    .typeString("STRATEGIC")
                    .pauseFor(4000)
                    .deleteAll()
                    .typeString("STEADY")
                    .pauseFor(4000)
                    .deleteAll()
                    .typeString("SUSTAINABLE")
                    .pauseFor(4000)
                    .deleteAll()
                    .typeString("SCALABLE")
                    .pauseFor(4000)
                    .deleteAll()
                    .typeString("STRATEGIC")
                    .pauseFor(4000)
                    .deleteAll()
                    .typeString("STEADY")
                    .pauseFor(4000)
                    .deleteAll()
                    .start()
                }}
                loop={true}
              />
            </b>
          </span>
          {""}
          growth
        </div>
      </Row>
      <br />
      <br />
      <Row className="center">
        <img
          src={fgRectangle}
          className="subhero-image"
          alt="Awesome alter Text"
        />
      </Row>
    </Container>
  )
}

export default SubheroOne

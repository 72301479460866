import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import moc from "../assets/images/banner-moc-1-1.png"
import boxes from "../assets/images/boxes.png"
import singleBox from "../assets/images/fg-box-single.png"
import { SliderButton } from "@typeform/embed-react"
import "../assets/css/hero.css"

const BannerOne = () => {
  return (
    <section className="banner-style-one" id="banner">
      {/* <span className="bubble-1 fg-gradient"></span>
      <span className="bubble-2"></span>
      <span className="bubble-3"></span>
      <span className="bubble-4"></span>
      <span className="bubble-5"></span>
      <span className="bubble-6"></span> */}

      <Container>
        <Row>
          <Col xl={6} lg={8}>
            <div className="content-block">
              <h4 className="pre-header">Feel Good. Fill Good. Full Good.</h4>
              <h3>
                Hassle Free <br /> fulfillment and <br /> logistics solutions.
              </h3>
              <p>
                Scale quickly, create strong brand identity and cut costs <br />
                effectively with our help.
              </p>
              <div className="button-block">
                <SliderButton href="#none" className="banner-btn" id="TC4d5mZv">
                  <span>Get Started</span>
                </SliderButton>
                <a href="#services" className="banner-btn center">
                  <span>Services</span>
                </a>
              </div>
            </div>
          </Col>
          <Col className="center hero-col">
            <div>
              <img
                src={singleBox}
                className="upper-left-box"
                alt="Awesome alter Text"
              />
              <div className="shadow upper-left-box">shadow</div>
            </div>
            <div>
              <img
                src={singleBox}
                className="upper-right-box"
                alt="Awesome alter Text"
              />
              <div className="shadow upper-right-box">shadow</div>
            </div>
            <div>
              <img
                src={singleBox}
                className="banner-mock"
                alt="Awesome alter Text"
              />
              <div className="shadow">shadow</div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default BannerOne

import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import ServiceCard from "./cards/service-card"
import BlockTitle from "./block-title"
import blockTitleCircle from "../assets/images/round-circle-1-1.png"
import squareLogo from "../assets/images/square-logo.png"
import squareLogoLg from "../assets/images/square-logo-lg.png"

const SERVICES_DATA = [
  {
    icon: "opins-icon-smartphone",
    title: "Fulfillment",
    text: "Dry, shelf-stable and frozen product fulfillment.",
    link: "#",
  },
  {
    icon: "opins-icon-smartphone-1",
    title: "Inventory Management",
    text: "Simple and transparent inventory dashboards.",
    link: "#",
  },
  {
    icon: "opins-icon-smartphone-2",
    title: "Logistics & More",
    text: "You handle sales. We handle the rest.",
    link: "#",
  },
]
const Services = () => {
  return (
    <section className="services-style-one" id="services">
      <Container>
        <BlockTitle
          textAlign="center"
          image={squareLogoLg}
          title={`Specializing in`}
        />
        <Row className="center">
          <div className="specialize caps center thin smaller">
            dry goods, specialty products, frozen food, kitting, seasonal
            projects and more.
          </div>
        </Row>
        <br />
        <br />
        <Row>
          {SERVICES_DATA.map(({ icon, title, text, link }, index) => (
            <Col lg={4} md={6} sm={12} key={index}>
              <ServiceCard title={title} text={text} link={link} />
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  )
}

export default Services

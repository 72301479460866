import React from "react"

const BlockTitleImgless = ({ textAlign, title }) => {
  return (
    <div className={`block-title text-${textAlign}`}>
      <h2>{title}</h2>
      <br />
    </div>
  )
}

export default BlockTitleImgless
